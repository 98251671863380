import Vue from "vue";

/*TITLE*/
document.title = "Llar Habitat Grao | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Llar Habitat Grao";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Llar Habitat Grao";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-planta-03-_-forest-colonial-_-rgb--20230116080143.jpg";
Vue.prototype.$image_kitchen = "cocina-_-forest-colonial-zinc-_-rgb--20230116080144.jpg";
Vue.prototype.$image_bath1 = "banyo-1_vela-grey-ariawhite_rgb--20230116080110.jpg";
Vue.prototype.$image_bath2 = "banyo-2_matika-silverstripe_rgb--20230116080133.jpg";
Vue.prototype.$image_room = "dormitorio-_-forest-colonial-_-rgb--20230116080148.jpg";
Vue.prototype.$image_facade = "edificio-grag-vista-8--20230116080158.jpg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-planta-03-_-forest-colonial-_-rgb--20230116080143.jpg",
  },
  {
    src: "salon-planta-4-_-forest-colonial-_-rgb--20230116080152.jpg",
  },
  {
    src: "salon-planta-03-_-forest-maple-_-rgb--20230116080101.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-_-forest-colonial-zinc-_-rgb--20230116080144.jpg",
  },
  {
    src: "cocina-_-forest-colonial-blanco-nubol-_-rgb--20230116080153.jpg",
  },
  {
    src: "cocina-_-forest-colonial-bison-_-rgb--20230116080100.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-1_vela-grey-ariawhite_rgb--20230116080110.jpg",
  },
  {
    src: "banyo-1-vela-natural-calcata-gold_rgb--20230116080122.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2_matika-silverstripe_rgb--20230116080133.jpg",
  },
  {
    src: "banyo-2_matika-bonestripe_rgb--20230116080141.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "dormitorio-_-forest-colonial-_-rgb--20230116080148.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "edificio-grag-vista-8--20230116080158.jpg",
  },
  {
    src: "edificio-grag-vista-6--20230116080107.jpg",
  },
  {
    src: "edificio-grag-vista-7--20230116080115.jpg",
  },
  {
    src: "edificio-grag-vista-5--20230116080124.jpg",
  },
  {
    src: "edificio-grag-vista-4--20230116080138.jpg",
  },
  {
    src: "edificio-grag-vista-2--20230116080101.jpg",
  },
  {
    src: "edificio-grag-vista-10--20230116080150.jpg",
  },
  {
    src: "edificio-grag-vista-9--20230116080156.jpg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/llarhabitatgrao/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = false;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=carretera%20del%20camí%20del%20grao%2012530%20burriana",
    text: "Carretera del Camí del Grao, 12530 Burriana",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=carretera%20del%20camí%20del%20grao%2012530%20burriana",
    text: "Carretera del Camí del Grao, 12530 Burriana",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:628009528",
    text: "628009528",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@llarhabitat.com",
    text: "info@llarhabitat.com",
  },
];
